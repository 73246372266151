
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { Media, Playlist, PlaylistMedia } from '@/types/index.d';

import Card from '@/components/Card.vue';
import DetailPanel from '@/components/DetailPanel.vue';
import RightSideModal from '@/components/RightSideModal.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SectionH2 from '@/components/heading/SectionH2.vue';
import SidePanel from '@/components/nav/SidePanel.vue';
import SidePanelItemBlock from '@/components/nav/SidePanelItemBlock.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import TimeTextField from '@/components/form/TimeTextField.vue';
import RoundButton from '@/components/form/RoundButton.vue';
import MediaDurationText from '@/components/MediaDurationText.vue';
import { postPlaylist, putPlaylist } from '@/services/axios';
import { getShuffledArray } from '@/services/helpers';
import { getMediaType } from '@/services/media';
import { cloneDeep, reduce } from 'lodash';

@Component({
  components: {
    Card,
    DetailPanel,
    RightSideModal,
    SectionH1,
    SectionH2,
    SidePanel,
    SidePanelItemBlock,
    SectionHeading,
    Thumbnail,
    TimeTextField,
    RoundButton,
    MediaDurationText,
  },
})
export default class PlaylistCreateEdit extends Vue {
  @Action loadPlaylists!: () => void;
  @State media!: Media[];
  @State opId!: number;
  @State playlists!: Playlist[];

  /**
   * The current selected media filter type id.
   * - 0 is all.
   * - 1 is video.
   * - 2 is sound.
   * - 3 is image.
   * - 4 is web.
   */
  filteredMediaTypeId: number = 0;

  defaultImageDuration: number = 10;
  playlistName: string = '';
  /**
   * List of selected media to create/edit a playlist.
   */
  selectedMedia: PlaylistMedia[] = [];

  /**
   * A mode indicates if the video media should set as a custom duration or
   * use its duration. 1 is using video duration, 2 is using custom duration.
   */
  editingVideoDurationMode: number = 1;
  /**
   * A current video media being edited as a dropdown. If null, the dropdown
   * will not be shown.
   */
  editingDurationVideo: PlaylistMediaIndex | null = null;

  /**
   * A pending duration in second unit of the video that is being edited in
   * the dropdown.
   */
  pendingVideoDuration: number | null = null;

  /**
   * Computed: selectable media list with filter applied.
   * @returns {Media[]} a selectable media list.
   */
  get mediaList(): Media[] {
    return this.media.filter(
      (media: Media) => this.filteredMediaTypeId === 0 || media.typ_id === this.filteredMediaTypeId
    );
  }

  @Watch('media', { deep: true })
  onMediaChanged() {
    this.prepareMediaPlaylists();
  }

  @Watch('playlists', { deep: true })
  onPlaylistsChanged() {
    this.prepareMediaPlaylists();
  }

  get editingPlaylistId(): number | null {
    // eslint-disable-next-line
    if (this.$route.name === 'playlists-edit-view')
      return parseInt(this.$route.params.playlistId, 10);
    return null;
  }

  /**
   * Computed: get total duration in format of `mm นาที ss วินาที` of the selected playlist.
   * @returns total duration of selected playlist in format of `mm นาที ss วินาที`.
   */
  get playlistDuration(): string {
    const playlistDuration: number = reduce(
      this.selectedMedia,
      (totalDuration: number, playlistMedia: PlaylistMedia) => {
        return (
          totalDuration +
          (playlistMedia.duration > 0 ? playlistMedia.duration : playlistMedia.video_duration)
        );
      },
      0
    );
    const minute: number = Math.floor(playlistDuration / 60);
    const second: number = Math.floor(playlistDuration % 60);
    return `${minute > 0 ? `${minute} นาที` : ''} ${second > 0 ? `${second} วินาที` : ''}`.trim();
  }

  addToSelectedMedia(media: Media): void {
    const playlistMedia: PlaylistMedia = {
      op_id: media.op_id,
      pos: this.selectedMedia.length,
      m_id: media.m_id,
      name: media.name,
      duration: media.typ_id === 1 ? -1 : DEFAULT_NON_VIDEO_MEDIA_DURATION_SECOND,
      typ_id: media.typ_id,
      content_url: media.content_url,
      mime: media.mime,
      thumbnail_url: media.thumbnail_url,
      m_dup_no: -1,
      video_duration: media.length,
    };
    this.selectedMedia.push(playlistMedia);
  }

  createPlaylist(): void {
    if (!this.playlistName) return;
    const preparedPlaylist = {
      name: this.playlistName,
      is_act: true,
      // Currently hard coded to `-1`
      media: this.selectedMedia.map(m => [m.m_id, m.duration]),
    };

    postPlaylist(this.opId, preparedPlaylist)
      .then(this.loadPlaylists)
      .then(() => {
        this.$router.push({
          name: 'playlists-view',
        });
      });
  }

  deselectMedia(index: number): void {
    this.selectedMedia = this.selectedMedia.filter((m, i) => i !== index);
  }

  doActionWithPlaylist(): void {
    if (this.$route.name === 'playlists-create-view') this.createPlaylist();
    else if (this.$route.name === 'playlists-edit-view') this.updatePlaylist();
  }

  filterMedia(typ_id: number): void {
    this.filteredMediaTypeId = typ_id;
  }

  getMediaType(typ_id: number): string {
    return getMediaType(typ_id);
  }

  move(index: number, diff: number): void {
    const temp = this.selectedMedia[index];
    this.$set(this.selectedMedia, index, this.selectedMedia[index + diff]);
    this.$set(this.selectedMedia, index + diff, temp);
  }

  prepareMediaPlaylists(): void {
    if (this.$route.name !== 'playlists-edit-view') return;
    if (!this.media.length || !this.playlists.length) return;

    const playlist = this.playlists.find(pl => pl.pl_id === this.editingPlaylistId);
    if (!playlist) return;
    const clonedPlaylist = cloneDeep(playlist);

    this.playlistName = clonedPlaylist.name;

    for (const item of clonedPlaylist.media) {
      const medium = this.media.find(m => m.m_id === item.m_id);
      if (medium) {
        this.selectedMedia.push(item);
      }
    }
  }

  shuffle(): void {
    this.selectedMedia = getShuffledArray(this.selectedMedia);
  }

  updatePlaylist(): void {
    if (!this.playlistName || !this.editingPlaylistId) return;
    const preparedPlaylist = {
      name: this.playlistName,
      is_act: true,
      // Currently hard coded to `-1`
      media: this.selectedMedia.map(m => [m.m_id, m.duration]),
    };

    putPlaylist(this.opId, this.editingPlaylistId, preparedPlaylist)
      .then(this.loadPlaylists)
      .then(() => {
        this.$router.push({
          name: 'playlists-view',
        });
      });
  }

  /**
   * Route back to the previous url.
   */
  routeBack(): void {
    this.$router.go(-1);
  }

  /**
   * Method to show a dropdown to edit a video duration.
   * @param {PlaylistMedia} playlistmedia a video media to be edited.
   * @param {number} index an index of the media in the editing playlist.
   */
  showEditVideoDurationDropdown(playlistMedia: PlaylistMedia, index: number): void {
    this.editingDurationVideo = {
      playlistMedia: playlistMedia,
      index: index,
    };
    this.editingVideoDurationMode = playlistMedia.duration < 0 ? 1 : 2;
    this.pendingVideoDuration =
      playlistMedia.duration < 0 ? playlistMedia.video_duration : playlistMedia.duration;
  }

  /**
   * Set mode of editing video duration.
   * @param {number} durationMode a editing mode:
   * - 1 is using video duration.
   * - 2 is using custom duration.
   */
  setEditingVideoDurationMode(durationMode: number): void {
    if (this.editingVideoDurationMode !== durationMode) {
      this.editingVideoDurationMode = durationMode;
      if (this.editingDurationVideo) {
        this.pendingVideoDuration =
          this.editingDurationVideo.playlistMedia.duration >= 0
            ? this.editingDurationVideo.playlistMedia.duration
            : this.editingDurationVideo.playlistMedia.video_duration;
      }
    }
  }

  /**
   * Get duration in millisecond unit of the video media. Under the hood,
   * if the media duration less than 0, it will use video duration. Otherwise,
   * it will the media custom duration.
   * @param {PlaylistMedia} playlistMedia a video media to get its duration in ms.
   * @returns {number} duration of the video in millisecond.
   */
  getVideoMediaDurationMs(playlistMedia: PlaylistMedia): number {
    if (this.editingVideoDurationMode === 1) {
      return playlistMedia.video_duration * 1000;
    }
    return (
      (this.pendingVideoDuration
        ? this.pendingVideoDuration
        : playlistMedia.duration < 0
        ? playlistMedia.video_duration
        : playlistMedia.duration) * 1000
    );
  }

  /**
   * Callback from TimeTextField element in the editing video duration dropdown.
   * @param {any} result a result from time text field.
   */
  onPendingVideoDurationTimeTextFieldChange(result: any) {
    this.pendingVideoDuration = Math.floor(result.totalMs / 1000);
  }

  /**
   * Callback from TimeTextField element in the non-video media duration item.
   * @param {any} result a result from time text field.
   */
  onTimeTextFieldNonVideoDurationChange(result: any) {
    this.selectedMedia[result.elementIndex]['duration'] = Math.floor(result.totalMs / 1000);
  }

  /**
   * Method to close the editing video duration dropdown.
   */
  closeEditingVideoDurationDropdown(): void {
    this.editingDurationVideo = null;
    this.pendingVideoDuration = null;
  }

  /**
   * Save the duration of the editing video media in the dropdown as well as close its
   * dropdown.
   */
  saveEditingVideoMediaDuration(): void {
    if (this.editingDurationVideo) {
      this.editingDurationVideo.playlistMedia.duration =
        this.editingVideoDurationMode === 1
          ? -1
          : this.pendingVideoDuration
          ? this.pendingVideoDuration
          : -1;
      this.closeEditingVideoDurationDropdown();
    }
  }

  mounted() {
    if (this.media.length && this.playlists.length) this.prepareMediaPlaylists();
  }
}

/**
 * Default non-video type media duration in second for newly adding media.
 */
const DEFAULT_NON_VIDEO_MEDIA_DURATION_SECOND: number = 10;

/**
 * A wrapper object containing the playlist media and its index in the selected playlist.
 */
interface PlaylistMediaIndex {
  playlistMedia: PlaylistMedia;
  index: number;
}
