
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * A component to show media duration in format of `mm:ss` where `m` is minute and `s` is second.
 * To set font color, font size, and font weight, set them at the component element level.
 */
@Component
export default class MediaDurationText extends Vue {
  /**
   * A duration in second unit.
   */
  @Prop({
    type: Number,
    default: 0,
  })
  readonly durationSecond!: number;

  /**
   * Computed: get a minute string part of the component.
   */
  get minuteString(): string {
    const minute: number = Math.floor(this.durationSecond / 60);
    return minute >= 10 ? `${minute}` : `0${minute}`;
  }

  /**
   * Computed: get a second string part of the component.
   */
  get secondString(): string {
    const second: number = Math.floor(this.durationSecond % 60);
    return second >= 10 ? `${second}` : `0${second}`;
  }
}
