
import { Component, Vue } from 'vue-property-decorator';

import PlaylistsMain from '@/components/playlists/PlaylistsMain.vue';
import PlaylistCreateEdit from '@/components/playlists/PlaylistCreateEdit.vue';

@Component({
  components: {
    PlaylistsMain,
    PlaylistCreateEdit,
  },
})
export default class PlaylistsView extends Vue {}
