
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import { AxiosResponse } from 'axios';

import { Playlist } from '@/types/index.d';

import Card from '@/components/Card.vue';
import RightSideModal from '@/components/RightSideModal.vue';
import PlaylistInfo from '@/components/playlists/PlaylistInfo.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SidePanel from '@/components/nav/SidePanel.vue';
import SidePanelItemBlock from '@/components/nav/SidePanelItemBlock.vue';

@Component({
  components: {
    Card,
    RightSideModal,
    PlaylistInfo,
    SectionH1,
    SidePanel,
    SidePanelItemBlock,
    SectionHeading,
  },
})
export default class PlaylistsMain extends Vue {
  // @Mutation setPlaylists!: (playlist: object[]) => void;
  @State playlists!: Playlist[];
  @Watch('playlists', { deep: true })
  onPlaylistsChanged() {
    this.selectPlaylistFromParams();
  }

  isShowingRightSideModal: boolean = false;
  selectedPlaylist: Playlist | null = null;

  mounted() {
    this.selectPlaylistFromParams();
  }

  closeRightSideModal(): void {
    this.isShowingRightSideModal = false;
  }

  openRightSideModal(): void {
    this.isShowingRightSideModal = true;
  }

  playlistDeleted(): void {
    this.selectedPlaylist = null;
  }

  selectPlaylist(playlist: Playlist): void {
    this.selectedPlaylist = playlist;
    this.$router.push({
      name: 'playlists-info-view',
      params: {
        playlistId: `${playlist.pl_id}`,
      },
    });
  }

  selectPlaylistFromParams(): void {
    if (this.$route && this.$route.params) {
      const opId = parseInt(this.$route.params.opId, 10);
      const playlistId = parseInt(this.$route.params.playlistId, 10);

      if (opId && playlistId) {
        // eslint-disable-next-line
        this.selectedPlaylist =
          this.playlists.find(pl => pl.op_id === opId && pl.pl_id === playlistId) || null;
      }
    }
  }
}
