
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { filter, some, map, find } from 'lodash';

import { Playlist } from '@/types/index.d';
import { getMediaType } from '@/services/media';

import DetailPanel from '@/components/DetailPanel.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SectionH2 from '@/components/heading/SectionH2.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import Card from '@/components/Card.vue';
import MediaDurationText from '@/components/MediaDurationText.vue';

import { deletePlaylist } from '@/services/axios';

@Component({
  components: {
    DetailPanel,
    SectionH1,
    SectionH2,
    SectionHeading,
    Thumbnail,
    Card,
    MediaDurationText,
  },
})
export default class PlaylistInfo extends Vue {
  @Prop() readonly selectedPlaylist!: Playlist;

  @Action loadPlaylists!: () => any;
  @State layouts!: any[];
  @State contentGroups!: any;

  get playlist(): Playlist {
    return this.selectedPlaylist;
  }

  get mappedLayoutsContentGroups(): any[] {
    const layouts = filter(this.layouts, l => some(l.slots, s => s.ct_grp_id));
    return map(layouts, l => {
      l.slots = map(l.slots, s => ({
        ...s,
        ct_grp: find(this.contentGroups, ctg => ctg.ct_grp_id === s.ct_grp_id),
      }));
      return l;
    });
  }

  get layoutsUsingCurrentPlaylist(): any[] {
    const { pl_id } = this.selectedPlaylist;
    return filter(this.mappedLayoutsContentGroups, l => {
      return some(l.slots, s => {
        if (s.ct_grp) {
          return some(s.ct_grp.schedules, sc => {
            return parseInt(sc.contain, 10) === pl_id;
          });
        }
        return false;
      });
    });
  }

  deletePlaylist(): void {
    if (!this.selectedPlaylist) return;

    if (window.confirm('คุณต้องการลบเพลย์ลิสต์นี้หรือไม่?')) {
      deletePlaylist(this.selectedPlaylist.op_id, this.selectedPlaylist.pl_id)
        .then(this.loadPlaylists)
        .then(() => this.$emit('playlist-deleted'));
    }
  }

  editPlaylist(): void {
    this.$router.push({
      name: 'playlists-edit-view',
      params: {
        playlistId: `${this.selectedPlaylist.pl_id}`,
      },
    });
  }

  getMediaType(typ_id: number): string {
    return getMediaType(typ_id);
  }

  openRightSideModal(): void {
    this.$emit('show-right-side-modal');
  }
}
